module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CECA MCP","short_name":"CECA","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/apple-touch-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8aaaed7090b8b6896a5cb13d89359c59"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/pdfjs-2.2.228-dist/web/viewer.html","/pdfs/web/index.html"],"appendScript":"/opt/build/repo/src/append-sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
